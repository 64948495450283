<template>
  <b-modal
    :title="`Disposal Request ${disposalRequestId}`"
    id="disposal-timeline-image-view"
    :no-close-on-backdrop="true"
    size="md"
    :footer-class="'disposal-modal-footer'"
    centered
  >
    <b-row>
      <b-col sm="12">
        <div align="center">
          <b-img
            v-img-orientation-changer
            :src="disposalImgUrl"
            alt="Responsive image"
            class="img-responsive img-thumbnail"
            fluid
          />
        </div>
      </b-col>
    </b-row>

    <template #modal-footer>
      <b-pagination
        align="center"
        :total-rows="disposal.irImageUrls.length"
        :per-page="1"
        v-model="currentPage"
        first-number
        last-number
        limit="3"
      />
    </template>
  </b-modal>
</template>

<script>
// Others
import EventBus from "@/shared/event-bus";
import _ from "lodash";

export default {
  name: "disposal-timeline-image-view",
  data() {
    return {
      disposal: {},
      currentPage: 1,
    };
  },
  computed: {
    disposalRequestId() {
      if (!_.isEmpty(this.disposal) && !_.isEmpty(this.disposal.disposalRequestId)) {
        return this.disposal.disposalRequestId;
      }
      return "";
    },
    disposalImgUrl() {
      if (!_.isEmpty(this.disposal) && !_.isEmpty(this.disposal.irImageUrls)) {
        return this.disposal.irImageUrls[this.currentPage - 1];
      }
      return "";
    },
  },
  mounted() {
    EventBus.$on("onSelDisposalTimelineImageView", (data) => {
      this.disposal = data.disposal;
    });
  },
  beforeDestroy() {
    EventBus.$off("onSelDisposalTimelineImageView");
  },
};
</script>

<style>
.disposal-modal-footer {
  justify-content: center !important;
}
</style>

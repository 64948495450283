<template>
<div>
    <span v-if="row.item.status == disposalStatus.DRAFT">
        <b-badge variant="secondary">{{ row.item.status }}</b-badge>
    </span>
    <span v-if="row.item.status == disposalStatus.FOR_APPROVAL">
        <b-badge variant="secondary">{{ row.item.status }}</b-badge>
    </span>
    <span v-if="row.item.status == disposalStatus.NBV_REVIEW">
        <b-badge variant="warning">{{ row.item.status }}</b-badge>
    </span>
    <span v-if="row.item.status == disposalStatus.APPROVED">
        <b-badge variant="success">{{ row.item.status }}</b-badge>
    </span>
    <span v-if="row.item.status == disposalStatus.CANCELLED">
        <b-badge variant="danger">{{ row.item.status }}</b-badge>
    </span>
    <span v-if="row.item.status == disposalStatus.REJECTED">
        <b-badge variant="danger">{{ row.item.status }}</b-badge>
    </span>
    <span v-if="row.item.status == disposalStatus.DONE">
        <b-badge variant="primary">{{ row.item.status }}</b-badge>
    </span>
</div>
</template>

<script>
// Others
import config from '@/config/env-constants';

export default {
    name: 'disposal-row-status',
    props: {
        row: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            disposalStatus: config.disposalStatus,
        };
    }
};
</script>

<template>
  <div>
    <span class="text-nowrap">
      <b-button
        key="show-details"
        size="sm"
        v-b-tooltip.hover.top="'Show/Hide Other Details'"
        variant="dark"
        @click.stop="row.toggleDetails"
        class="mr-1"
      >
        <i class="fa fa-eye-slash" v-if="row.detailsShowing"></i>
        <i class="fa fa-eye" v-else></i>
      </b-button>
      <b-button
        key="edit"
        size="sm"
        v-b-modal.edit-disposal
        v-b-tooltip.hover.top="'Edit Details'"
        @click.stop="updateDisposal(row.item)"
        v-if="
          row.item.status != disposalStatus.APPROVED &&
          row.item.status != disposalStatus.CANCELLED &&
          row.item.status != disposalStatus.DONE &&
          !isAccounting &&
          !isApprover &&
          !isViewer
        "
        variant="warning"
        class="mr-1"
      >
        <i class="fa fa-pencil"></i>
      </b-button>
      <b-button
        key="cancel"
        size="sm"
        v-b-modal.cancel-disposal
        v-b-tooltip.hover.top="'Cancel'"
        variant="danger"
        class="mr-1"
        @click.stop="updateDisposal(row.item)"
        v-if="
          row.item.status != disposalStatus.APPROVED &&
          row.item.status != disposalStatus.CANCELLED &&
          row.item.status != disposalStatus.DONE &&
          !isAccounting &&
          !isApprover &&
          !isViewer
        "
      >
        <em class="fa fa-ban"></em>
      </b-button>
      <b-button
        key="submit-draft"
        size="sm"
        v-b-modal.submit-disposal
        v-b-tooltip.hover.top="'Submit For NBV Review'"
        variant="success"
        class="mr-1"
        @click.stop="updateDisposal(row.item)"
        v-if="row.item.status == disposalStatus.DRAFT && !isAccounting && !isViewer"
      >
        <em class="fa fa-check"></em>
      </b-button>
      <b-button
        key="submit-nbv"
        size="sm"
        v-b-modal.confirm-netbook-disposal
        v-b-tooltip.hover.top="'Submit For Approval'"
        variant="success"
        class="mr-1"
        @click.stop="updateDisposal(row.item)"
        v-if="
          row.item.status == disposalStatus.NBV_REVIEW &&
          (isAccounting || isSuperAdmin || isManager || isSuperVisor)
        "
      >
        <em class="fa fa-check"></em>
      </b-button>
      <b-button
        key="approve"
        size="sm"
        v-b-modal.for-approval-disposal
        v-b-tooltip.hover.top="'Approve'"
        variant="success"
        class="mr-1"
        @click.stop="updateDisposal(row.item)"
        v-if="row.item.status == disposalStatus.FOR_APPROVAL && isApprover"
      >
        <em class="fa fa-check"></em>
      </b-button>
      <b-button
        key="done"
        size="sm"
        v-b-modal.mark-as-done-disposal
        v-b-tooltip.hover.top="'Mark Disposal Request as Done'"
        variant="success"
        class="mr-1"
        @click.stop="updateDisposal(row.item)"
        v-if="
          (row.item.status == disposalStatus.APPROVED ||
            (row.item.status == disposalStatus.REJECTED && row.item.irSubmitted)) &&
          (isSuperAdmin || isManager || isSuperVisor)
        "
      >
        <em class="fa fa-check"></em>
      </b-button>
    </span>
  </div>
</template>

<script>
// Others
import EventBus from "@/shared/event-bus";
import config from "@/config/env-constants";

export default {
  name: "disposal-row-actions",
  props: {
    row: {
      type: Object,
      required: true,
    },
    isAccounting: {
      type: Boolean,
      required: true,
    },
    isApprover: {
      type: Boolean,
      required: true,
    },
    isSuperVisor: {
      type: Boolean,
      required: true,
    },
    isManager: {
      type: Boolean,
      required: true,
    },
    isSuperAdmin: {
      type: Boolean,
      required: true,
    },
    isViewer: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      disposalStatus: config.disposalStatus,
    };
  },
  methods: {
    updateDisposal(item) {
      this.$store.commit("SET_CURR_ITEM_DISPOSAL", item);
      EventBus.$emit("onUpdateDisposal", item);
    },
  },
};
</script>

import { db } from '@/config/firebase';
import _ from 'lodash';

function getDisposalRequestListener(filter, callback) {
    let query = generateQuery(filter);
    let listener = query.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
            let disposalRequest = change.doc.data();
            disposalRequest.id = change.doc.id;

            callback(change.type, disposalRequest);
        });
    });
    return listener;
}

function generateQuery(filter) {
    let query = db.collection("assetDisposalRequests");

    if (filter.fromTimestamp && filter.fromTimestamp > 0) {
        query = query.where("dateCreated", ">=", filter.fromTimestamp);
    }

    if (filter.toTimestamp && filter.toTimestamp > 0) {
        query = query.where("dateCreated", "<=", filter.toTimestamp);
    }

    if (filter.status && filter.status.length > 0) {
        query = query.where("status", "==", filter.status);
    }

    if (filter.assetType.id && filter.assetType.id.length > 0) {
        query = query.where("assetDetails.details.name", "==", filter.assetType.name);
    }

    if (filter.assetCode && filter.assetCode.length > 0) {
        query = query.where("assetDetails.assetCode", "==", filter.assetCode);
    }

    if (filter.repairId && filter.repairId.length > 0) {
        query = query.where("disposalRequestId", "==", filter.repairId);
    }

    if (!filter.isSuperAdmin) {
        // Added for filtering company view only but not on Filter Options
        if (filter.companyIds && !_.isEmpty(filter.companyIds)) {
            query = query.where("companyId", "in", filter.companyIds);
        }
    }

    return query;
}

export default {
    getDisposalRequestListener,
}
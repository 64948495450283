<template>
  <b-modal
    id="add-disposal"
    title="Add Disposal Request"
    size="lg"
    ref="modal"
    ok-title="Add"
    @ok="handleOk"
    @show="onReset"
    :cancel-disabled="disableConfirmButtons"
    :ok-disabled="disableConfirmButtons"
    :no-close-on-backdrop="true"
    centered
  >
    <loading
      :active.sync="isLoading"
      loader="spinner"
      color="#20A8D8"
      :is-full-page="false"
    />

    <b-form @submit.stop.prevent="handleSubmit" novalidate>
      <b-container fluid>
        <b-row class="my-2">
          <b-col sm="8">
            <b>ASSET DETAILS</b>
          </b-col>
        </b-row>
        <b-row class="my-12">
          <b-col lg="6" md="6" sm="12">
            <b-form-group label="Asset Type">
              <v-select
                name="Asset Type"
                class="style-chooser"
                label="text"
                placeholder=" - Please select - "
                :options="assetTypeOptions"
                :reduce="(assetType) => assetType.value"
                v-model="selAssetType"
                v-validate="'selectRequired'"
                @input="onChangeAssetType"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    No results found for
                    <em>
                      <strong>{{ search }}</strong>
                    </em>
                  </template>
                  <em :style="{ opacity: 0.5 }" v-else>
                    Start typing to search for a company
                  </em>
                </template>
              </v-select>
              <span v-show="errors.has('Asset Type')" class="help-block">
                {{ errors.first("Asset Type") }}
              </span>
            </b-form-group>
          </b-col>

          <b-col lg="6" md="6" sm="12">
            <b-form-group
              label="Asset Code"
              label-for="Asset Code"
              description="List of assets that are marked as Damaged"
            >
              <v-select
                name="Asset Code"
                class="style-chooser"
                label="text"
                placeholder=" - Please select - "
                :options="assetCodeOptions"
                :reduce="(assetCode) => assetCode.value"
                v-model="selAssetCode"
                @input="onChangeAssetCode"
                v-validate="'selectRequired'"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    No results found for
                    <em>
                      <strong>{{ search }}</strong>
                    </em>
                  </template>
                  <em :style="{ opacity: 0.5 }" v-else>
                    Start typing to search for an asset code
                  </em>
                </template>
              </v-select>
              <span v-show="errors.has('Asset Code')" class="help-block">
                {{ errors.first("Asset Code") }}
              </span>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="my-12">
          <b-col lg="6" md="6" sm="12">
            <b-form-group label="Remarks" label-for="description">
              <b-form-textarea
                name="Description"
                type="text"
                v-model="form.reasonForDisposal"
                maxlength="200"
                v-validate="{
                  required: true,
                  regex: remarksRegex,
                }"
                :rows="3"
                placeholder="Description"
              />
              <span v-show="errors.has('Description')" class="help-block">
                {{ errors.first("Description") }}
              </span>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="my-2">
          <b-col sm="8">
            <b>LIST OF APPROVERS</b>
          </b-col>
        </b-row>
        <b-row class="my-12">
          <b-col lg="6" md="6" sm="12">
            <b-form-group
              label="Approver"
              description="The person you want to include to approve this disposal request"
            >
              <v-select
                name="Approver"
                class="style-chooser"
                label="text"
                placeholder=" - Please select - "
                :options="filteredApproverOptions"
                :reduce="(approver) => approver.value"
                v-model="selApprover"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    No results found for
                    <em>
                      <strong>{{ search }}</strong>
                    </em>
                  </template>
                  <em :style="{ opacity: 0.5 }" v-else>
                    Start typing to search for approver
                  </em>
                </template>
              </v-select>
              <span v-show="errors.has('Approver')" class="help-block">
                {{ errors.first("Approver") }}
              </span>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12">
            <b-button
              class="btn-add-approver"
              @click.stop="onAddApprover"
              variant="primary"
              >Add Approver</b-button
            >
          </b-col>
        </b-row>
        <b-container v-if="form.approvers.length > 0">
          <b-row class="my-2">
            <b-col sm="8">
              <b>SELECTED APPROVERS</b>
            </b-col>
          </b-row>
          <b-row class="my-12">
            <b-col lg="8" md="8" sm="12">
              <b-table
                ref="approversTable"
                borderless
                small
                :items="form.approvers"
                :fields="fields"
                responsive
              >
                <template v-slot:cell(actions)="row">
                  <span class="text-nowrap">
                    <b-button
                      size="sm"
                      variant="danger"
                      @click.stop="onRemoveApprover(row.item.id)"
                      class="mr-1"
                    >
                      <em class="fa fa-trash"></em>
                    </b-button>
                  </span>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-container>
      </b-container>
    </b-form>
  </b-modal>
</template>

<script>
// Util
import { DisposalUtil } from "@/utils/disposalUtil";
import { DropDownItemsUtil } from "@/utils/dropDownItemsUtil";
import { ValidationUtil } from "@/utils/validationUtil";
import { DateUtil } from "@/utils/dateutil";

// DAO & API
import assetDAO from "@/database/assets";
import userDAO from "@/database/users";
import disposalRequestAPI from "@/api/disposalRequestApi";

// Others
import EventBus from "@/shared/event-bus";
import config from "@/config/env-constants";
import Loading from "vue-loading-overlay";
import _ from "lodash";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "add-disposal",
  components: {
    Loading,
  },
  props: {
    assetTypeOptions: {
      type: Array,
      required: true,
    },
    loggedUser: {
      type: Object,
      required: true,
    },
    isMaintenance: {
      type: Boolean,
      required: true,
    },
    isSuperVisor: {
      type: Boolean,
      required: true,
    },
    isManager: {
      type: Boolean,
      required: true,
    },
    isSuperAdmin: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      form: {
        ...DisposalUtil.getDefaultDisposalObj(),
      },
      fields: [
        {
          key: "id",
          label: "Email",
        },
        {
          key: "name",
          label: "Name",
        },
        "actions",
      ],

      assetCodeOptions: [],
      filteredApproverOptions: [],

      allAssetCodesObj: {},

      selAssetType: config.assetTypeDefaultValue,
      selAssetCode: config.assetCodeDefaultValue,
      selApprover: config.userDefaultValue,

      currUserId: this.$store.getters.loggedUser.id,
      loggedUserCompany: this.$store.getters.loggedUserCompany,

      // Check for loader
      isLoading: false,
    };
  },
  computed: {
    disableConfirmButtons() {
      return this.isLoading;
    },
    disposalRequestId() {
      return this.form.disposalRequestId;
    },
    remarksRegex() {
      return config.remarksRegex;
    },
  },
  mounted() {
    setTimeout(async () => {
      try {
        // show loading indicator
        this.isLoading = true;
        this.fillAssetCodeOptions();
      } catch (_error) {
        console.log(_error);
        this.$toaster.error("Error loading data. Please reload the page again.");
      }

      // hide loading indicator
      this.isLoading = false;
    }, config.timeout);
  },
  methods: {
    async onChangeAssetType(newVal) {
      this.selAssetType = newVal;

      try {
        // show loading indicator
        this.isLoading = true;

        this.allAssetCodesObj = await assetDAO.getDamageAssetByAssetTypeId(
          this.selAssetType.id
        );
      } catch (_error) {
        this.$toaster.error("Error loading data. Please reload the page again.");
      }

      // hide loading indicator
      this.isLoading = false;

      this.fillAssetCodeOptions();
    },
    async onChangeAssetCode() {
      if (this.selAssetCode.currCompanyId) {
        this.isLoading = true;
        var companyId = this.selAssetCode.currCompanyId;
        this.selApprover = config.userDefaultValue;

        var approverUsersObj = await userDAO.getApproverUsers();
        var filteredApprovers = Object.values(approverUsersObj).filter((z) =>
          z.companyAccess.some((x) => x.id === companyId)
        );

        var approverOptions = DropDownItemsUtil.retrieveActiveUsers(filteredApprovers);

        this.filteredApproverOptions = [...approverOptions];

        var currApprovers = _.cloneDeep(this.form.approvers);
        this.form.approvers = [
          ...currApprovers.filter((a) =>
            this.filteredApproverOptions.some((o) => o.value.id === a.id)
          ),
        ];

        this.isLoading = false;
      }
    },
    onAddApprover() {
      if (
        this.selApprover.id != null &&
        !this.form.approvers.some((approver) => approver.id === this.selApprover.id)
      ) {
        this.form.approvers.push(this.selApprover);
      }
    },
    onRemoveApprover(id) {
      this.form.approvers = this.form.approvers.filter((approver) => approver.id !== id);
    },
    fillAssetCodeOptions() {
      let filterConditions;

      if (this.isMaintenance || this.isSuperVisor || this.isManager) {
        if (this.loggedUserCompany.hasParentCompany || this.isMaintenance) {
          filterConditions = (o) => o.currCompanyId === this.loggedUserCompany.id;
        }
      }

      const filteredObj = _.filter(this.allAssetCodesObj, filterConditions);

      if (!_.isEmpty(filteredObj)) {
        var disposalStatuses = _.filter(
          Object.values(config.disposalStatus),
          (o) => o != config.disposalStatus.CANCELLED
        );

        var repairStatuses = _.filter(
          Object.values(config.repairStatus),
          (o) => o != config.repairStatus.CANCELLED
        );

        this.assetCodeOptions = _.filter(
          DropDownItemsUtil.retrieveAssetCodes(filteredObj),
          (assetCode) =>
            !assetCode.value || // Check if assetCode.value is null or undefined
            (!disposalStatuses.includes(assetCode.value.disposalStatus) && // Check if disposalStatus is not CANCELLED
              !repairStatuses.includes(assetCode.value.repairStatus)) // Check if repairStatus is not CANCELLED
        );
      } else {
        this.assetCodeOptions = [
          {
            value: config.assetCodeDefaultValue,
            text: "- Please select - ",
          },
        ];
        this.selAssetCode = config.assetCodeDefaultValue;
      }
    },
    cleanupFormFields() {
      // Removes excess whitespace
      this.form.reasonForDisposal = ValidationUtil.removeExcessWhiteSpace(
        this.form.reasonForDisposal
      );
    },
    generateDisposal(form, assetCodeObj, assetTypeObj) {
      let currTimestamp = DateUtil.getCurrentTimestamp();
      let assetCode = _.find(Object.values(this.allAssetCodesObj), {
        id: assetCodeObj.id,
      });

      return {
        ...form,
        createdBy: this.currUserId,
        disposalRequestId: "DR" + currTimestamp,
        assetDetails: {
          assetType: assetTypeObj.name,
          assetCode: assetCodeObj.id,
          details: assetCodeObj.details,
        },
        dateCreated: currTimestamp,
        approverIds: form.approvers.map((approver) => approver.id),
        companyId: assetCode.currCompanyId,
      };
    },
    getParam() {
      let disposal = this.generateDisposal(
        this.form,
        this.selAssetCode,
        this.selAssetType
      );

      return {
        currUserId: this.currUserId,
        disposal: disposal,
      };
    },
    async handleOk(evt) {
      // Prevent modal from closing
      evt.preventDefault();

      this.cleanupFormFields();

      if (this.form.approvers.length === 0) {
        this.$toaster.warning("Please add approver.");
        return;
      }

      let isValid = await this.$validator.validateAll();
      if (!isValid) {
        this.$toaster.warning("Please address the field/s with invalid input.");
        return;
      }

      // show loading indicator
      this.isLoading = true;

      await this.handleSubmit();

      // hide loading indicator
      this.isLoading = false;
    },
    async handleSubmit() {
      // show loading indicator
      this.isLoading = true;

      try {
        let param = this.getParam();
        let { data } = await disposalRequestAPI.saveDisposalRequest(param);

        if (data.isSuccess) {
          this.$toaster.success(data.message);
          EventBus.$emit("onCloseDisposalRequest", data.disposalRequest);
          this.$refs.modal.hide();
        } else {
          this.$toaster.error(
            `Error saving Disposal Request "${this.disposalRequest}". Please try again.`
          );
        }
      } catch (_error) {
        this.$toaster.error(
          `Error saving Disposal Request "${this.disposalRequest}". Please try again.`
        );
      }

      // hide loading indicator
      this.isLoading = false;
    },
    onReset() {
      this.form = {
        ...DisposalUtil.getDefaultDisposalObj(),
      };

      this.assetCodeOptions = [
        {
          value: config.assetCodeDefaultValue,
          text: "- Please select - ",
        },
      ];

      this.selAssetType = config.assetTypeDefaultValue;
      this.selAssetCode = config.assetCodeDefaultValue;
      this.selApprover = config.userDefaultValue;
      this.form.approvers = [];

      // reset validation
      this.$validator.reset();
      this.errors.clear();
    },
  },
};
</script>

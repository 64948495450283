<template>
  <div>
    <b-tabs>
      <b-tab title="Primary Information" active>
        <b-card-text>
          <b-form-group
            label="Disposal ID"
            label-cols-sm="2"
            label-class="font-weight-bold pt-0"
            label-align-sm="right"
          >
            {{ row.item.disposalRequestId }}
          </b-form-group>

          <b-form-group
            label="Remarks"
            label-cols-sm="2"
            label-class="font-weight-bold pt-0"
            label-align-sm="right"
          >
            <span class="truncate-text">
              <truncate
                collapsed-text-class="collapsed"
                clamp="Show More"
                :length="100"
                less="Show Less"
                type="html"
                :text="row.item.reasonForDisposal"
              />
            </span>
          </b-form-group>

          <b-form-group
            label="Status"
            label-cols-sm="2"
            label-class="font-weight-bold pt-0"
            label-align-sm="right"
          >
            <DisposalRowStatus :row="row" />
          </b-form-group>

          <b-form-group
            label="Created By"
            label-cols-sm="2"
            label-class="font-weight-bold pt-0"
            label-align-sm="right"
          >
            {{ row.item.createdBy }}
          </b-form-group>

          <b-form-group
            label="Date Created"
            label-cols-sm="2"
            label-class="font-weight-bold pt-0"
            label-align-sm="right"
          >
            {{ getFormattedDateWithTime(row.item.dateCreated) }}
          </b-form-group>

          <b-form-group
            label="Updated By"
            label-cols-sm="2"
            label-class="font-weight-bold pt-0"
            label-align-sm="right"
          >
            {{ row.item.updatedBy ? row.item.updatedBy : "-" }}
          </b-form-group>

          <b-form-group
            label="Date Updated"
            label-cols-sm="2"
            label-class="font-weight-bold pt-0"
            label-align-sm="right"
          >
            {{
              row.item.dateUpdated ? getFormattedDateWithTime(row.item.dateUpdated) : "-"
            }}
          </b-form-group>
        </b-card-text>
      </b-tab>
      <b-tab title="Asset Details">
        <b-card-text>
          <b-row>
            <b-col lg="8" md="8" sm="12">
              <b-form-group
                label="Asset Code"
                label-cols-sm="4"
                label-class="font-weight-bold pt-0"
                label-align-sm="right"
              >
                {{ row.item.assetDetails.assetCode }}
              </b-form-group>

              <b-form-group
                label="Asset Type"
                label-cols-sm="4"
                label-class="font-weight-bold pt-0"
                label-align-sm="right"
              >
                {{ row.item.assetDetails.assetType }}
              </b-form-group>

              <b-form-group
                label="Condition"
                label-cols-sm="4"
                label-class="font-weight-bold pt-0"
                label-align-sm="right"
              >
                <b-badge
                  v-if="row.item.assetDetails.details.condition === 'Good'"
                  variant="success"
                >
                  Good
                </b-badge>
                <b-badge
                  v-else-if="row.item.assetDetails.details.condition === 'Damaged'"
                  variant="danger"
                >
                  Damaged
                </b-badge>
                <b-badge v-else variant="secondary">
                  {{
                    row.item.assetDetails.details.condition
                      ? row.item.assetDetails.details.condition
                      : "-"
                  }}
                </b-badge>
              </b-form-group>

              <b-form-group
                label="Net Book Value"
                label-cols-sm="4"
                label-class="font-weight-bold pt-0"
                label-align-sm="right"
              >
                <span class="numFont">
                  {{
                    row.item.assetDetails.details.netBookValue
                      ? row.item.assetDetails.details.netBookValue
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : "0.00"
                  }}
                  PHP
                </span>
              </b-form-group>

              <b-form-group
                label="Last Inventory"
                label-cols-sm="4"
                label-class="font-weight-bold pt-0"
                label-align-sm="right"
              >
                <span class="numFont">
                  {{
                    row.item.assetDetails.details.inventoriedBy
                      ? `${row.item.assetDetails.details.inventorySessionId} at
                                    ${getFormattedDateWithTime(
                                      row.item.assetDetails.details.lastInventoryDate
                                    )} by ${row.item.assetDetails.details.inventoriedBy}`
                      : "-"
                  }}
                </span>
              </b-form-group>
            </b-col>
            <b-col lg="4" md="4" sm="12">
              <b-img
                :src="row.item.assetDetails.details.imgUrl"
                alt="Responsive image"
                class="img-responsive"
                fluid
                thumbnail
              />
            </b-col>
          </b-row>
        </b-card-text>
      </b-tab>
      <b-tab title="Timeline">
        <b-card-text>
          <DisposalTimelineDetailsView :row="row" />
        </b-card-text>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
// Component
import DisposalRowStatus from "@/views/maintenance/disposal/DisposalRowStatus";
import DisposalTimelineDetailsView from "@/views/maintenance/disposal/DisposalTimelineDetailsView.vue";

// Util
import { DateUtil } from "@/utils/dateutil";

// Others
import truncate from "vue-truncate-collapsed";

export default {
  name: "disposal-details-view",
  components: {
    DisposalRowStatus,
    DisposalTimelineDetailsView,
    truncate,
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getFormattedDateWithTime(date) {
      return DateUtil.getFormattedDateWithTime(date);
    },
  },
};
</script>

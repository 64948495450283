<template>
    <div class="ml-4">
        <Loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
        <div class="timeline d-flex">
            <div class="d-flex flex-column align-items-center">
                <img :src="renderHorizontalIcon(1)" alt width="75" />
                <small>Disposal Request</small>
                <small>Submitted</small>
                <small class="text-muted">{{ renderDate(1) }}</small>
            </div>
            <div class="d-flex flex-column align-items-center">
                <img :src="renderHorizontalIcon(2)" alt width="75" />
                <small>{{ row.item.status !== disposalStatus.CANCELLED ? 'Net Book Value' : 'Disposal Request'
                }}</small>
                <small>{{ row.item.status !== disposalStatus.CANCELLED ? 'Confirmed' : 'Cancelled' }}</small>
                <small class="text-muted"> {{ renderDate(2) }}</small>
            </div>
            <div class="d-flex flex-column align-items-center">
                <img :src="renderHorizontalIcon(3)" alt width="75" />
                <small>Disposal Request</small>
                <small>{{ row.item.status !== disposalStatus.REJECTED ? 'Approved' : 'Rejected' }}</small>
                <small class="text-muted">{{ renderDate(3) }}</small>
            </div>
            <div class="d-flex flex-column align-items-center">
                <img :src="renderHorizontalIcon(4)" alt width="75" />
                <small>Disposal Request</small>
                <small>Marked as Done</small>
                <small class="text-muted">{{ renderDate(4) }}</small>
            </div>
        </div>

        <div class="mt-4" v-if="row.item.status === disposalStatus.DRAFT">
            <i>Disposal request is currently in draft status.</i>
        </div>
        <template v-else>
            <div class="container mx-0 mt-4">
                <div class="timeline-vertical d-flex flex-column">
                    <template v-for="(log, index) in changeLogs">
                        <template v-if="log.new.status === disposalStatus.NBV_REVIEW">
                            <div v-bind:key="index" class="d-flex flex-row align-items-start">
                                <img :src="renderVerticalIcon(null, index)" alt width="25" />
                                <small class="text-muted flex-shrink-0">{{ showFormattedDateTime(log.new.dateCreated)
                                }}</small>
                                <small>Disposal request status updated to
                                    <b-badge variant="warning">{{ disposalStatus.NBV_REVIEW }}</b-badge>
                                </small>
                            </div>
                            <div v-bind:key="'a' + index" class="d-flex flex-row align-items-start">
                                <img :src="renderVerticalIcon(log.new.status, index)" alt width="25" />
                                <small class="text-muted flex-shrink-0">{{ showFormattedDateTime(log.new.dateCreated)
                                }}</small>
                                <div class="d-flex flex-column">
                                    <strong>Disposal request created: {{ log.new.disposalRequestId }}</strong>
                                    <small>Created by {{ log.new.createdBy }}</small>
                                </div>
                            </div>
                        </template>
                        <template v-if="log.new.status === disposalStatus.CANCELLED">
                            <div v-bind:key="index" class="d-flex flex-row align-items-start">
                                <img :src="renderVerticalIcon(null, index)" alt width="25" />
                                <small class="text-muted flex-shrink-0">{{ showFormattedDateTime(log.new.dateUpdated)
                                }}</small>
                                <small>Disposal request status updated to
                                    <b-badge variant="danger">{{ disposalStatus.CANCELLED }}</b-badge>
                                </small>
                            </div>
                            <div v-bind:key="'a' + index" class="d-flex flex-row align-items-start">
                                <img :src="renderVerticalIcon(log.new.status, index)" alt width="25" />
                                <small class="text-muted flex-shrink-0">{{ showFormattedDateTime(log.new.dateUpdated)
                                }}</small>
                                <div class="d-flex flex-column">
                                    <strong>Disposal request cancelled</strong>
                                    <small>Cancelled by {{ log.new.updatedBy }}</small>
                                </div>
                            </div>
                        </template>
                        <template v-if="log.new.status === disposalStatus.FOR_APPROVAL && !log.new.irSubmitted">
                            <div v-bind:key="index" class="d-flex flex-row align-items-start">
                                <img :src="renderVerticalIcon(null, index)" alt width="25" />
                                <small class="text-muted flex-shrink-0">{{
                                    showFormattedDateTime(log.new.dateNbvReviewed)
                                }}</small>
                                <small>Disposal request status updated to
                                    <b-badge variant="secondary">{{ disposalStatus.FOR_APPROVAL }}</b-badge>
                                </small>
                            </div>
                            <div v-bind:key="'a' + index" class="d-flex flex-row align-items-start">
                                <img :src="renderVerticalIcon(log.new.status, index)" alt width="25" />
                                <small class="text-muted flex-shrink-0">{{
                                    showFormattedDateTime(log.new.dateNbvReviewed)
                                    }}</small>
                                <div class="d-flex flex-column">
                                    <strong>Net book value confirmed: PHP {{
                                        log.new.assetDetails.details.netBookValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g,
                                            ",")
                                    }}</strong>
                                    <small>Confirmed by {{ log.new.nbvReviewedBy }}</small>
                                </div>
                            </div>
                        </template>
                        <template v-if="log.new.status === disposalStatus.FOR_APPROVAL && log.new.irSubmitted">
                            <div v-bind:key="index" class="d-flex flex-row align-items-start">
                                <img :src="renderVerticalIcon(null, index)" alt width="25" />
                                <small class="text-muted flex-shrink-0">{{
                                    showFormattedDateTime(log.new.dateUpdated)
                                }}</small>
                                <small>Disposal request status updated to
                                    <b-badge variant="secondary">{{ disposalStatus.FOR_APPROVAL }}</b-badge>
                                </small>
                            </div>
                            <div v-bind:key="'a' + index" class="d-flex flex-row align-items-start">
                                <img :src="renderVerticalIcon(log.new.status, index)" alt width="25" />
                                <small class="text-muted flex-shrink-0">{{
                                    showFormattedDateTime(log.new.dateUpdated)
                                }}</small>
                                <div class="d-flex flex-column">
                                    <strong>Incident report submitted</strong>
                                    <small>Submitted by {{ log.new.updatedBy }}</small>
                                    <a href="#" @click.prevent="openIrImages(log.new)">
                                        <i>View image(s)</i>
                                    </a>
                                </div>
                            </div>
                        </template>
                        <template v-if="log.new.status === disposalStatus.APPROVED">
                            <div v-bind:key="index" class="d-flex flex-row align-items-start">
                                <img :src="renderVerticalIcon(null, index)" alt width="25" />
                                <small class="text-muted flex-shrink-0">{{ showFormattedDateTime(log.new.dateApproved)
                                }}</small>
                                <small>Disposal request status updated to
                                    <b-badge variant="success">{{ disposalStatus.APPROVED }}</b-badge>
                                </small>
                            </div>
                            <div v-bind:key="'a' + index" class="d-flex flex-row align-items-start">
                                <img :src="renderVerticalIcon(log.new.status, index)" alt width="25" />
                                <small class="text-muted flex-shrink-0">{{ showFormattedDateTime(log.new.dateApproved)
                                }}</small>
                                <div class="d-flex flex-column">
                                    <strong>Disposal request approved</strong>
                                    <small>Approved by {{ log.new.approvedBy }}</small>
                                    <small>Remarks: <i>{{ log.new.reasonForApproval }}</i></small>
                                </div>
                            </div>
                        </template>
                        <template v-if="log.new.status === disposalStatus.REJECTED">
                            <div v-bind:key="index" class="d-flex flex-row align-items-start">
                                <img :src="renderVerticalIcon(null, index)" alt width="25" />
                                <small class="text-muted flex-shrink-0">{{ showFormattedDateTime(log.new.dateRejected)
                                }}</small>
                                <small>Disposal request status updated to
                                    <b-badge variant="danger">{{ disposalStatus.REJECTED }}</b-badge>
                                </small>
                            </div>
                            <div v-bind:key="'a' + index" class="d-flex flex-row align-items-start">
                                <img :src="renderVerticalIcon(log.new.status, index)" alt width="25" />
                                <small class="text-muted flex-shrink-0">{{ showFormattedDateTime(log.new.dateRejected)
                                }}</small>
                                <div class="d-flex flex-column">
                                    <strong>Disposal request rejected</strong>
                                    <small>Rejected by {{ log.new.rejectedBy }}</small>
                                    <small>Remarks: <i>{{ log.new.reasonForRejection }}</i></small>
                                </div>
                            </div>
                        </template>
                        <template v-if="log.new.status === disposalStatus.DONE">
                            <div v-bind:key="index" class="d-flex flex-row align-items-start">
                                <img :src="renderVerticalIcon(null, index)" alt width="25" />
                                <small class="text-muted flex-shrink-0">{{ showFormattedDateTime(log.new.dateUpdated)
                                }}</small>
                                <small>Disposal request status updated to
                                    <b-badge variant="primary">{{ disposalStatus.DONE }}</b-badge>
                                </small>
                            </div>
                            <div v-bind:key="'a' + index" class="d-flex flex-row align-items-start">
                                <img :src="renderVerticalIcon(log.new.status, index)" alt width="25" />
                                <small class="text-muted flex-shrink-0">{{ showFormattedDateTime(log.new.dateUpdated)
                                }}</small>
                                <div class="d-flex flex-column">
                                    <strong>Disposal request marked as done</strong>
                                    <small>Done by {{ log.new.updatedBy }}</small>
                                    <small>Remarks: <i>{{ log.new.reasonForDisposal }}</i></small>
                                </div>
                            </div>
                        </template>
                    </template>
                </div>
            </div>

            <span v-if="seeMoreChangeLogs.length > 3" class="truncate-text" @click="toggleSeeState">
                <template v-if="!seeLess">
                    <a>See Less</a>
                </template>
                <template v-if="seeLess">
                    <a>See More</a>
                </template>
            </span>
        </template>

        <DisposalTimelineImageView />
    </div>
</template>

<script>
// Database
import auditTrailLogsDAO from '@/database/auditTrailLogs';

// Component
import DisposalTimelineImageView from "@/views/maintenance/disposal/DisposalTimelineImageView";

import cancel from '@/assets/images/timelines/cancel.svg';
import reject from '@/assets/images/timelines/reject.svg';
import documentFill from '@/assets/images/timelines/document-fill.svg';
import documentOutline from '@/assets/images/timelines/document-outline.svg';
import approveFill from '@/assets/images/timelines/approve-fill.svg';
import approveOutline from '@/assets/images/timelines/approve-outline.svg';
import nbvReviewFill from '@/assets/images/timelines/nbv-review-fill.svg';
import nbvReviewOutline from '@/assets/images/timelines/nbv-review-outline.svg';
import doneFill from '@/assets/images/timelines/done-fill.svg';
import doneOutline from '@/assets/images/timelines/done-outline.svg';

import documentGray from '@/assets/images/timelines/document-gray.svg';
import nbvReviewGray from '@/assets/images/timelines/nbv-review-gray.svg';
import approveGray from '@/assets/images/timelines/approve-gray.svg';
import rejectGray from '@/assets/images/timelines/reject-gray.svg';
import doneGray from '@/assets/images/timelines/done-gray.svg';
import cancelGray from '@/assets/images/timelines/cancel-gray.svg';
import circleGray from '@/assets/images/timelines/circle-gray.svg';
import circleOrange from '@/assets/images/timelines/circle-orange.svg';

// Util
import { DateUtil } from '@/utils/dateutil';

// Others
import EventBus from '@/shared/event-bus';
import config from '@/config/env-constants';
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import _ from "lodash";

export default {
    name: 'disposal-timeline-details-view',
    components: {
        DisposalTimelineImageView,
        Loading
    },
    props: {
        row: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            icons: {
                cancel,
                reject,
                documentFill,
                documentOutline,
                approveFill,
                approveOutline,
                nbvReviewFill,
                nbvReviewOutline,
                doneFill,
                doneOutline,
                documentGray,
                nbvReviewGray,
                approveGray,
                rejectGray,
                doneGray,
                cancelGray,
                circleGray,
                circleOrange
            },
            disposalStatus: config.disposalStatus,

            changeLogs: [],
            seeMoreChangeLogs: [],
            seeLessChangeLogs: [],
            disposalRequestId: '',

            loggedUser: this.$store.getters.loggedUser,

            seeLess: true,
            isLoading: false
        };
    },
    mounted() {
        setTimeout(async () => {
            this.disposalRequestId = this.row.item.id;

            await this.retrieveChangeLog(this.disposalRequestId);
        }, config.timeout);
    },
    watch: {
        seeLess() {
            if (this.seeLess) {
                this.changeLogs = [...this.seeLessChangeLogs];
            } else {
                this.changeLogs = [...this.seeMoreChangeLogs];
            }
        },
        'row.item.status': {
            async row() {
                await this.retrieveChangeLog(this.disposalRequestId);
            },
            deep: true
        }
    },
    methods: {
        async retrieveChangeLog(disposalRequestId) {
            this.isLoading = true;

            this.changeLogs = [];

            if (disposalRequestId) {
                const param = {
                    collection: 'assetDisposalRequests',
                    id: disposalRequestId
                }

                const resultObj = await auditTrailLogsDAO.getAuditTrailLogs(param);
                const results = Object.values(resultObj);

                const draft = _.maxBy(_.filter(results, result => result.new.status === config.disposalStatus.DRAFT),
                    dr => dr.dateCreated);
                const nbvReview = _.maxBy(_.filter(results, result => result.new.status === config.disposalStatus.NBV_REVIEW
                    && result.new.status !== result.old.status), dr => dr.dateCreated);
                const forApproval = _.maxBy(_.filter(results, result => result.new.status === config.disposalStatus.FOR_APPROVAL
                    && result.new.status !== result.old.status && !result.new.irSubmitted), dr => dr.dateCreated);
                const incidentReport = _.maxBy(_.filter(results, result => result.new.status === config.disposalStatus.FOR_APPROVAL
                    && result.new.status !== result.old.status && result.new.irSubmitted), dr => dr.dateCreated);
                const approved = _.maxBy(_.filter(results, result => result.new.status === config.disposalStatus.APPROVED
                    && result.new.status !== result.old.status), dr => dr.dateCreated);
                const rejected = _.maxBy(_.filter(results, result => result.new.status === config.disposalStatus.REJECTED
                    && result.new.status !== result.old.status), dr => dr.dateCreated);
                const cancelled = _.maxBy(_.filter(results, result => result.new.status === config.disposalStatus.CANCELLED
                    && result.new.status !== result.old.status), dr => dr.dateCreated);
                const done = _.maxBy(_.filter(results, result => result.new.status === config.disposalStatus.DONE
                    && result.new.status !== result.old.status), dr => dr.dateCreated);

                this.seeMoreChangeLogs.push(draft);

                if (nbvReview) {
                    this.seeMoreChangeLogs.push(nbvReview);
                }

                if (forApproval) {
                    this.seeMoreChangeLogs.push(forApproval);
                }

                if (incidentReport) {
                    this.seeMoreChangeLogs.push(incidentReport);
                }

                if (approved) {
                    this.seeMoreChangeLogs.push(approved);
                }

                if (rejected) {
                    this.seeMoreChangeLogs.push(rejected);
                }

                if (cancelled) {
                    this.seeMoreChangeLogs.push(cancelled);
                }

                if (done) {
                    this.seeMoreChangeLogs.push(done);
                }

                this.seeMoreChangeLogs = _.orderBy(this.seeMoreChangeLogs, ['dateCreated'], ['desc']);
                this.seeLessChangeLogs = [...this.seeMoreChangeLogs];

                if (this.seeLessChangeLogs.length > 2) {
                    this.seeLessChangeLogs.splice(2);
                }

                this.changeLogs = [...this.seeLessChangeLogs];

                this.isLoading = false;
            }
        }, showFormattedDateTime(date) {
            return DateUtil.getFormattedShortDateWithTime(date);
        }, renderHorizontalIcon(position) {
            switch (position) {
                case 1:
                    return this.row.item.status === config.disposalStatus.DRAFT
                        ? this.icons.documentOutline
                        : this.icons.documentFill;
                case 2:
                    return this.row.item.status === config.disposalStatus.CANCELLED
                        ? this.icons.cancel
                        : !this.row.item.dateNbvReviewed
                            ? this.icons.nbvReviewOutline
                            : this.icons.nbvReviewFill;
                case 3:
                    {
                        const incidentReport = _.maxBy(_.filter(this.seeMoreChangeLogs, result => result.new.status === config.disposalStatus.FOR_APPROVAL
                            && result.new.irSubmitted), dr => dr.dateCreated);

                        return this.row.item.status === config.disposalStatus.REJECTED
                            || (this.row.item.status === config.disposalStatus.FOR_APPROVAL && incidentReport)
                            ? this.icons.reject
                            : !this.row.item.dateApproved
                                ? this.icons.approveOutline
                                : this.icons.approveFill;
                    }
                default:
                    return this.row.item.status === config.disposalStatus.DONE
                        ? this.icons.doneFill
                        : this.icons.doneOutline;
            }
        }, renderVerticalIcon(status, index) {
            switch (status) {
                case config.disposalStatus.NBV_REVIEW:
                    return this.icons.documentGray;
                case config.disposalStatus.FOR_APPROVAL:
                    return this.icons.nbvReviewGray;
                case config.disposalStatus.APPROVED:
                    return this.icons.approveGray;
                case config.disposalStatus.REJECTED:
                    return this.icons.rejectGray;
                case config.repairStatus.CANCELLED:
                    return this.icons.cancelGray;
                case config.disposalStatus.DONE:
                    return this.icons.doneGray;
                default:
                    return index === 0 ? this.icons.circleOrange : this.icons.circleGray;
            }
        },
        renderDate(position) {
            switch (position) {
                case 1:
                    return this.row.item.status === config.disposalStatus.DRAFT
                        ? "-"
                        : this.showFormattedDateTime(this.row.item.dateCreated)
                case 2:
                    return this.row.item.status === config.disposalStatus.CANCELLED
                        ? this.showFormattedDateTime(this.row.item.dateUpdated)
                        : this.showFormattedDateTime(this.row.item.dateNbvReviewed);
                case 3:
                    return this.row.item.status === config.disposalStatus.REJECTED
                        ? this.showFormattedDateTime(this.row.item.dateRejected)
                        : this.showFormattedDateTime(this.row.item.dateApproved);
                default:
                    return this.row.item.status === config.disposalStatus.DONE
                        ? this.showFormattedDateTime(this.row.item.dateUpdated)
                        : '-';
            }
        },
        toggleSeeState() {
            this.seeLess = !this.seeLess;
        },
        openIrImages(disposal) {
            EventBus.$emit('onSelDisposalTimelineImageView', {
                disposal,
            });
            this.$bvModal.show('disposal-timeline-image-view');
        }
    }
}
</script>
<style scoped>
.timeline {
    justify-content: space-between;
    position: relative;
    width: 80%;
    max-width: 880px;
    z-index: 1;
}

/* Horizontal line */
.timeline::before {
    content: "";
    position: absolute;
    top: 25%;
    left: 10%;
    width: 80%;
    height: 4px;
    background-color: #f18f01;
    z-index: -1;
}

.timeline-vertical {
    gap: 30px;
    position: relative;
    z-index: 1;
}

/* Vertical line */
.timeline-vertical::before {
    content: "";
    position: absolute;
    width: 4px;
    background-color: #dadada;
    top: 20px;
    /* Starts below the first circle */
    height: calc(100% - 40px);
    /* Stops above the last circle */
    left: 13px;
    transform: translateX(-50%);
    z-index: -1;
}

.timeline-vertical>div {
    gap: 20px;
}

.timeline-vertical div:first-child>* {
    color: #f18f01 !important;
}

.truncate-text {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: #f18f01;
}
</style>
// Others
import config from '@/config/env-constants';

function getDefaultDisposalObj() {
    return {
        disposalRequestId: '',
        status: config.disposalStatus.DRAFT,
        assetDetails: {
            details: {},
            assetCode: ''
        },
        reasonForDisposal: '',
        reasonForRejection: '',
        reasonForApproval: '',
        approvedBy: '',
        createdBy: '',
        updatedBy: '',
        cancelledBy: '',
        rejectedBy: '',
        dateCreated: null,
        dateUpdated: null,
        dateCancelled: null,
        dateApproved: null,
        dateRejected: null,
        disposalDate: null,
        approvers: []
    };
}
function cleanupFields(disposal) {
    let disposalObj = { ...disposal };

    delete disposalObj['assetCode'];
    delete disposalObj['_showDetails'];
    delete disposalObj['Date Created'];

    return disposalObj;
}
export const DisposalUtil = {
    getDefaultDisposalObj,
    cleanupFields
};

<template>
	<b-modal
		id="mark-as-done-disposal"
		title="Mark Disposal Request as Done"
		ref="modal"
		ok-title="Proceed"
		ok-variant="primary"
		cancel-title="Close"
		@ok="handleOk"
		@show="onReset"
		:cancel-disabled="disableConfirmButtons"
		:ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true"
		centered
	>
		<loading
			:active.sync="isLoading"
			loader="spinner"
			color="#20A8D8"
			:is-full-page="false"
		/>

		<div class="confirm-message">
			Are you sure you want to mark
			<b class="numFont">{{ selDisposal.disposalRequestId }}</b> as done?
		</div>
	</b-modal>
</template>

<script>
// Util
import { DisposalUtil } from '@/utils/disposalUtil';

// API
import disposalRequestAPI from '@/api/disposalRequestApi';

// Others
import EventBus from '@/shared/event-bus';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	name: 'mark-as-done-disposal',
	components: {
		Loading,
	},
	data() {
		return {
			selDisposal: {},

			currUserId: this.$store.getters.loggedUser.id,

			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		disposalRequestId() {
			return this.selDisposal.disposalRequestId;
		},
	},
	mounted() {
        EventBus.$on('onUpdateDisposal', (disposal) => {
			this.selDisposal = disposal;
		});
	},
	methods: {
		generateDisposal() {
			let disposal = DisposalUtil.cleanupFields(this.selDisposal);

			return {
				...disposal,
				status: config.disposalStatus.DONE,
			};
		},
		getParam() {
			let disposal = this.generateDisposal();

			return {
				currUserId: this.currUserId,
				disposal: disposal,
			};
		},
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			await this.markAsDoneRDisposal();
		},
		async markAsDoneRDisposal() {
			try {
				let param = this.getParam();
				let { data } = await disposalRequestAPI.saveDisposalRequest(param);

				if (data.isSuccess) {
					this.$toaster.success(data.message);
					EventBus.$emit(
						'onCloseDisposalRequest',
						data.disposalRequest
					);
					this.$refs.modal.hide();
				} else {
					this.$toaster.error(
						`Error marking Disposal Request "${this.disposalRequestId}" as done. Please try again.`
					);
				}
			} catch (_error) {
				this.$toaster.error(
					`Error marking Disposal Request "${this.disposalRequestId}" as done. Please try again.`
				);
			}

			// hide loading indicator
			this.isLoading = false;
		},
		onReset() {
			// do nothing
		},
	},
	beforeDestroy() {
		EventBus.$off('onUpdateDisposal');
	},
};
</script>

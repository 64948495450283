<template>
    <b-modal
      id="confirm-netbook-disposal"
      title="Confirm Net Book Value"
      ref="modal"
      ok-title="Confirm"
      ok-variant="primary"
      cancel-title="Close"
      @ok="handleOk"
      @show="onReset"
      :cancel-disabled="disableConfirmButtons"
      :ok-disabled="disableConfirmButtons"
      :no-close-on-backdrop="true"
      centered
    >
      <loading
        :active.sync="isLoading"
        loader="spinner"
        color="#20A8D8"
        :is-full-page="false"
      />
  
      <div class="confirm-message">
        Are you sure you want to confirm the Net Book Value
        <b class="numFont">{{ selDisposal.disposalRequestId }}</b
        >?
      </div>
      <b-container v-if="!isEmpty">
        <b-row class="mt-4">
          <b-col cols="4" class="text-md-right" sm="4">
            <label for="assetType" class="font-weight-bold pt-0">Asset Type</label>
          </b-col>
          <b-col cols="8" sm="8">
            {{ selDisposal.assetDetails.details.name }}
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4" class="text-md-right" sm="4">
            <label for="assetCode" class="font-weight-bold">Asset Code</label>
          </b-col>
          <b-col cols="8" sm="8">
            {{ selDisposal.assetDetails.assetCode }}
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4" class="text-md-right my-auto" sm="4">
            <label for="remarks" class="font-weight-bold p-0">Net Book Value</label>
          </b-col>
          <b-col cols="8" sm="8" class="my-auto">
            <b-form-input
              name="NetBook Value"
              v-model="netBookValue"
              v-validate="'required|positive_decimal'"
              type="number"
            />
            <span v-show="errors.has('NetBook Value')" class="help-block">
              Please enter a positive number
            </span>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="4" class="text-md-right" sm="4">
            <label for="remarks" class="font-weight-bold p-0">Status</label>
          </b-col>
          <b-col cols="8" sm="8">
            <b-badge
              v-if="selDisposal.assetDetails.details.condition === 'Good'"
              variant="success"
            >
              Good
            </b-badge>
            <b-badge
              v-else-if="selDisposal.assetDetails.details.condition === 'Damaged'"
              variant="danger"
            >
              Damaged
            </b-badge>
            <b-badge v-else variant="secondary">
              {{
                selDisposal.assetDetails.details.condition
                  ? selDisposal.assetDetails.details.condition
                  : "-"
              }}
            </b-badge>
          </b-col>
        </b-row>
  
        <b-row v-if="selDisposal.assetDetails.details.imgUrl">
          <b-col cols="4" class="text-md-right" sm="4">
            <label for="remarks" class="font-weight-bold p-0">Image</label>
          </b-col>
          <b-col cols="8" sm="8">
            <b-img
              :src="selDisposal.assetDetails.details.imgUrl"
              alt="Responsive image"
              class="img-responsive"
              fluid
              thumbnail
            />
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </template>
  
  <script>
  //Util
  import { DisposalUtil } from "@/utils/disposalUtil";
  import { DateUtil } from "@/utils/dateutil";
  
  // API
  import disposalRequestAPI from "@/api/disposalRequestApi";
  
  // Others
  import EventBus from "@/shared/event-bus";
  import config from "@/config/env-constants";
  import Loading from "vue-loading-overlay";
  import "vue-loading-overlay/dist/vue-loading.css";
  import _ from "lodash";
  
  export default {
    name: "confirm-netbook-disposal",
    components: {
      Loading,
    },
    props: {
      loggedUser: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        selDisposal: {},
        netBookValue: 0,
  
        currUserId: this.$store.getters.loggedUser.id,
  
        // Check for loader
        isLoading: false,
      };
    },
    computed: {
      disableConfirmButtons() {
        return this.isLoading;
      },
      disposalRequestId() {
        return this.selDisposal.disposalRequestId;
      },
      isEmpty() {
        return _.isEmpty(this.selDisposal);
      },
    },
    mounted() {
      EventBus.$on("onUpdateDisposal", (disposal) => {
        this.selDisposal = disposal;
        this.netBookValue = 0;
        if (disposal.assetDetails.details.netBookValue) {
          this.netBookValue = disposal.assetDetails.details.netBookValue;
        }
      });
    },
    methods: {
      generateDisposal() {
        let currTimestamp = DateUtil.getCurrentTimestamp();
  
        // Convert to number
        this.selDisposal.assetDetails.details.netBookValue = parseFloat(this.netBookValue);
        var disposal = DisposalUtil.cleanupFields(this.selDisposal);
  
        return {
          ...disposal,
          updatedBy: this.loggedUser.firstName + " " + this.loggedUser.lastName,
          dateUpdated: currTimestamp,
          status: config.disposalStatus.FOR_APPROVAL,
        };
      },
      getParam() {
        let disposal = this.generateDisposal();
  
        return {
          currUserId: this.currUserId,
          disposal: disposal,
        };
      },
      async handleOk(evt) {
        // Prevent modal from closing
        evt.preventDefault();
  
        let isValid = await this.$validator.validateAll();
  
        if (isValid) {
          // show loading indicator
          this.isLoading = true;
          await this.submitDisposalRequest();
        }
      },
      async submitDisposalRequest() {
        try {
          let param = this.getParam();
          let { data } = await disposalRequestAPI.saveDisposalRequest(param);
  
          if (data.isSuccess) {
            this.$toaster.success(data.message);
            EventBus.$emit("onCloseDisposalRequest", data.disposalRequest);
            this.$refs.modal.hide();
          } else {
            this.$toaster.error(
              `Error submitting Disposal Request "${this.disposalRequestId}". Please try again.`
            );
          }
        } catch (_error) {
          console.log(_error);
          this.$toaster.error(
            `Error submitting Disposal Request "${this.disposalRequestId}". Please try again.`
          );
        }
  
        // hide loading indicator
        this.isLoading = false;
      },
      onReset() {
        // do nothing
      },
    },
    beforeDestroy() {
      EventBus.$off("onUpdateDisposal");
    },
  };
  </script>
  
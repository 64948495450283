import axios from 'axios';
import config from '@/config/env-constants';

function getProjectId() {
    if (config.currEnv === 'pjl') {
        return `ayun-${config.currEnv}`;
    } else {
        return `ares-${config.currEnv}-app`;
    }
}

export default {

    baseUrl: `https://us-central1-${getProjectId()}.cloudfunctions.net`,

    getDisposalRequest(param) {
        let url = `${this.baseUrl}/getDisposalRequest`;
        return axios.post(url, {
            currUserId: param.currUserId,
            filterBy: JSON.stringify(param.filterBy),
            view: param.view
        });
    },
    saveDisposalRequest(param) {
        let url = `${this.baseUrl}/saveDisposalRequest`;
        return axios.post(url, {
            currUserId: param.currUserId,
            disposalRequest: JSON.stringify(param.disposal),
        });
    },
    approveMultipleDisposalRequests(param) {
        let url = `${this.baseUrl}/approveMultipleDisposalRequests`;
        return axios.post(url, {
            currUserId: param.currUserId,
            disposalRequestArr: JSON.stringify(param.disposalArr),
        });
    }
}
<template>
  <b-modal
    id="multiple-for-approval-disposal"
    title="Approve Disposal Requests"
    ref="modal"
    ok-title="Proceed"
    @ok="handleOk"
    @show="onReset"
    :cancel-disabled="disableConfirmButtons"
    :ok-disabled="disableConfirmButtons"
    :no-close-on-backdrop="true"
    centered
    size="lg"
  >
    <loading
      :active.sync="isLoading"
      loader="spinner"
      color="#20A8D8"
      :is-full-page="false"
    />

    <b-col sm="12">
      <label>Are you sure you want to approve the selected disposal requests?</label>
    </b-col>
    <b-col sm="12">
      <b-form-group class="mt-4" label="Remarks" label-for="Remarks">
        <b-form-textarea
          name="Remarks"
          type="text"
          maxlength="200"
          v-model="form.remarks"
          v-validate="{
            required: true,
            regex: remarksRegex,
          }"
          :rows="3"
          placeholder="Remarks"
        />
        <span v-show="errors.has('Remarks')" class="help-block">
          {{ errors.first("Remarks") }}
        </span>
      </b-form-group>
    </b-col>
  </b-modal>
</template>
<script>
// API
import disposalRequestAPI from "@/api/disposalRequestApi";

// Util
import { DisposalUtil } from "@/utils/disposalUtil";
import { DateUtil } from "@/utils/dateutil";

// Others
import EventBus from "@/shared/event-bus";
import config from "@/config/env-constants";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import _ from "lodash";

export default {
  name: "multiple-for-approval-disposal",
  components: {
    Loading,
  },
  data() {
    return {
      form: {
        remarks: `Disposal approved by batch on ${DateUtil.getFormattedDateWithTime2(
          new Date()
        )}.`,
      },

      disposalsToApprove: [],

      currUserId: this.$store.getters.loggedUser.id,

      // Check for loader
      isLoading: false,
    };
  },
  mounted() {
    EventBus.$on("onUpdateMultipleDisposal", (selDisposals) => {
      this.processSelDisposals(selDisposals);
    });
  },
  computed: {
    disableConfirmButtons() {
      return this.isLoading;
    },
    remarksRegex() {
      return config.remarksRegex;
    },
  },
  methods: {
    processSelDisposals(selDisposals) {
      // Show Loader
      this.Loading = true;

      this.disposalsToApprove = [];

      _.forEach(selDisposals, (selDisposal) => {
        if (selDisposal.status === config.disposalStatus.FOR_APPROVAL) {
          this.disposalsToApprove.push(selDisposal);
        }
      });

      // Hide Loader
      this.Loading = false;
    },
    generateDisposals() {
      let disposals = [];

      _.forEach(this.disposalsToApprove, (disposalToApprove) => {
        let disposal = DisposalUtil.cleanupFields(disposalToApprove);
        disposal.status = config.disposalStatus.APPROVED;
        disposal.remarks = this.form.remarks;

        disposals.push(disposal);
      });

      return [...disposals];
    },
    getParam() {
      let disposals = this.generateDisposals();

      return {
        currUserId: this.currUserId,
        disposalArr: [...disposals],
      };
    },
    async handleOk(evt) {
      // Prevent modal from closing
      evt.preventDefault();

      let isValid = await this.$validator.validateAll();
      if (!isValid) {
        this.$.warning("Please address the field/s with invalid input.");
        return;
      }

      this.handleSubmit();
    },
    async handleSubmit() {
      // show loading indicator
      this.isLoading = true;

      try {
        let param = this.getParam();
        let { data } = await disposalRequestAPI.approveMultipleDisposalRequests(param);

        if (data.isSuccess) {
          this.$toaster.success(data.message);
          this.$refs.modal.hide();
          EventBus.$emit("onCloseMultipleDisposalRequest", data.disposalRequestArr);
        } else {
          this.$toaster.warning(data.message);
        }
      } catch (_error) {
        this.$toaster.error("Error activating asset(s). Please try again.");
      }

      // hide loading indicator
      this.isLoading = false;
    },
    onReset() {
      this.form.remarks = "";
      // reset validation
      this.$validator.reset();
      this.errors.clear();
    },
  },
  beforeDestroy() {
    EventBus.$off("onUpdateMultipleDisposal");
  },
};
</script>

<template>
  <b-modal
    id="for-approval-disposal"
    title="Approve Disposal Request"
    ref="modal"
    :no-close-on-backdrop="true"
    centered
    @show="onReset"
    size="lg"
  >
    <loading
      :active.sync="isLoading"
      loader="spinner"
      color="#20A8D8"
      :is-full-page="false"
    />

    <div class="confirm-message">
      Are you sure you want to approve disposal request
      <b class="numFont">{{ selDisposal.disposalRequestId }}</b
      >?
    </div>

    <b-row v-if="selDisposal.assetDetails">
      <b-col sm="8">
        <b-form-group
          label="Asset Type"
          label-cols-sm="4"
          label-class="font-weight-bold pt-0"
          label-align-sm="right"
          class="mb-0"
        >
          {{ selDisposal.assetDetails.assetType }}
        </b-form-group>

        <b-form-group
          label="Asset Code"
          label-cols-sm="4"
          label-class="font-weight-bold pt-0"
          label-align-sm="right"
          class="mb-0"
        >
          {{ selDisposal.assetDetails.assetCode }}
        </b-form-group>

        <b-form-group
          label="Net Book Value"
          label-cols-sm="4"
          label-class="font-weight-bold pt-0"
          label-align-sm="right"
          class="mb-0"
        >
          <span class="numFont">
            {{
              selDisposal.assetDetails.details.netBookValue
                ? selDisposal.assetDetails.details.netBookValue
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : "0.00"
            }}
            PHP
          </span>
        </b-form-group>

        <b-form-group
          label="Status"
          label-cols-sm="4"
          label-class="font-weight-bold pt-0"
          label-align-sm="right"
          class="mb-0"
        >
          <b-badge
            v-if="selDisposal.assetDetails.details.condition === 'Good'"
            variant="success"
          >
            Good
          </b-badge>
          <b-badge
            v-else-if="selDisposal.assetDetails.details.condition === 'Damaged'"
            variant="danger"
          >
            Damaged
          </b-badge>
          <b-badge v-else variant="secondary">
            {{
              selDisposal.assetDetails.details.condition
                ? selDisposal.assetDetails.details.condition
                : "-"
            }}
          </b-badge>
        </b-form-group>
      </b-col>
    </b-row>
    <hr />
    <i class="info"
      ><i class="fa fa-info-circle"></i>&nbsp; By clicking <strong>Approve</strong> or
      <strong>Reject</strong>, you won't be able to make any further edits on this
      disposal request.</i
    >
    <b-form-group
      class="mt-2"
      label="Remarks"
      label-for="Remarks"
      description="Please indicate the reason of approval/rejection here"
    >
      <b-form-textarea
        name="Remarks"
        type="text"
        maxlength="200"
        v-model="form.remarks"
        v-validate="{
          required: true,
          regex: remarksRegex,
        }"
        :rows="3"
        placeholder="Remarks"
      />
      <span v-show="errors.has('Remarks')" class="help-block">
        {{ errors.first("Remarks") }}
      </span>
    </b-form-group>

    <template #modal-footer="{ close }">
      <b-button size="md" @click="close()" :disabled="disableConfirmButtons"
        >Close</b-button
      >
      <b-button
        size="md"
        variant="danger"
        @click="handleReject()"
        :disabled="disableConfirmButtons"
      >
        Reject
      </b-button>
      <b-button
        size="md"
        variant="success"
        @click="handleOk()"
        :disabled="disableConfirmButtons"
        >Approve</b-button
      >
    </template>
  </b-modal>
</template>

<script>
// API
import disposalRequestAPI from "@/api/disposalRequestApi";

// Util
import { DisposalUtil } from "@/utils/disposalUtil";

// Others
import EventBus from "@/shared/event-bus";
import config from "@/config/env-constants";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "for-approval-disposal",
  components: {
    Loading,
  },
  data() {
    return {
      form: {
        remarks: "",
      },

      selDisposal: {},

      currUserId: this.$store.getters.loggedUser.id,

      // Check for loader
      isLoading: false,
    };
  },
  mounted() {
    EventBus.$on("onUpdateDisposal", (disposal) => {
      this.selDisposal = disposal;
    });
  },
  computed: {
    disableConfirmButtons() {
      return this.isLoading;
    },
    disposalRequestId() {
      return this.selDisposal.disposalRequestId;
    },
    remarksRegex() {
      return config.remarksRegex;
    },
  },
  methods: {
    generateDisposal(status) {
      let disposal = DisposalUtil.cleanupFields(this.selDisposal);

      return {
        ...disposal,
        status,
        remarks: this.form.remarks,
      };
    },
    getParam(status) {
      let disposal = this.generateDisposal(status);

      return {
        currUserId: this.currUserId,
        disposal: disposal,
      };
    },
    async handleOk() {
      // show loading indicator
      this.isLoading = true;

      let isValid = await this.$validator.validateAll();
      if (!isValid) {
        this.$toaster.warning("Please address the field/s with invalid input.");

        // hide loading indicator
        this.isLoading = false;
        return;
      }

      await this.processDisposal(config.disposalStatus.APPROVED);
    },
    async handleReject() {
      // show loading indicator
      this.isLoading = true;

      let isValid = await this.$validator.validateAll();
      if (!isValid) {
        this.$toaster.warning("Please address the field/s with invalid input.");

        // hide loading indicator
        this.isLoading = false;
        return;
      }

      await this.processDisposal(config.disposalStatus.REJECTED);
    },
    async processDisposal(status) {
      // show loading indicator
      this.isLoading = true;

      let statusName =
        status === config.disposalStatus.APPROVED ? "approving" : "rejecting";

      try {
        let param = this.getParam(status);
        let { data } = await disposalRequestAPI.saveDisposalRequest(param);

        if (data.isSuccess) {
          this.$toaster.success(data.message);
          EventBus.$emit("onCloseDisposalRequest", data.disposalRequest);
          this.$refs.modal.hide();
        } else {
          this.$toaster.error(
            `Error ${statusName} Disposal Request "${this.disposalRequestId}". Please try again.`
          );
        }
      } catch (_error) {
        this.$toaster.error(
          `Error ${statusName} Disposal Request "${this.disposalRequestId}". Please try again.`
        );
      }

      // hide loading indicator
      this.isLoading = false;
    },
    onReset() {
      this.form.remarks = "";

      // reset validation
      this.$validator.reset();
      this.errors.clear();
    },
  },
  beforeDestroy() {
    EventBus.$off("onUpdateDisposal");
  },
};
</script>

<style>
.info {
  color: #f18f01 !important;
}
</style>

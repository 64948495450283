<template>
  <div class="animated fadeIn">
    <b-card class="card-border mt-4">
      <b-card-title><i class="fa fa-users"></i> Disposal Management</b-card-title>
      <b-card-sub-title
        >Handles the management of assets set for disposal</b-card-sub-title
      >
      <div fluid class="px-2 mt-4">
        <loading
          :active.sync="isLoading"
          loader="spinner"
          color="#20A8D8"
          :is-full-page="false"
        />

        <!-- Filter  -->
        <b-row class="mt-2">
          <b-col sm="12" md="3" lg="3">
            <b-button
              v-b-popover.hover.right="'Toggle to show/hide filter options'"
              v-b-toggle.collapse-1
              class="filter"
            >
              FILTER OPTIONS
            </b-button>
          </b-col>
          <b-col sm="12">
            <!-- Collapsible Filter Options -->
            <b-collapse id="collapse-1" class="mt-2">
              <b-card>
                <b-row no-gutters>
                  <b-col lg="4" md="6" sm="12" class="mr-4">
                    <b-form-group label="Date From">
                      <b-form-datepicker
                        v-model="filterBy.dateFrom"
                        locale="en"
                        reset-button
                        label-reset-button="Clear"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',
                          weekday: 'short',
                        }"
                        :date-disabled-fn="dateFromDisabled"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col lg="4" md="6" sm="12" class="mr-4">
                    <b-form-group label="Date To">
                      <b-form-datepicker
                        v-model="filterBy.dateTo"
                        locale="en"
                        reset-button
                        label-reset-button="Clear"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',
                          weekday: 'short',
                        }"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col lg="4" md="6" sm="12" class="mr-4">
                    <b-form-group label="Status">
                      <v-select
                        class="style-chooser"
                        label="text"
                        placeholder=" - Please select - "
                        :options="filterByOptions.disposalStatusOptions"
                        :reduce="(status) => status.value"
                        v-model="filterBy.status"
                      >
                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            No results found for
                            <em>
                              <strong>{{ search }}</strong>
                            </em>
                          </template>
                          <em
                            :style="{
                              opacity: 0.5,
                            }"
                            v-else
                          >
                            Start typing to search status
                          </em>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col lg="4" md="5" sm="12" class="mr-4">
                    <b-form-group label="Asset Type">
                      <v-select
                        class="style-chooser"
                        label="text"
                        placeholder=" - Please select - "
                        :options="filterByOptions.assetTypes"
                        :reduce="(assetType) => assetType.value"
                        v-model="filterBy.assetType"
                      >
                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            No results found for
                            <em>
                              <strong>{{ search }}</strong>
                            </em>
                          </template>
                          <em
                            :style="{
                              opacity: 0.5,
                            }"
                            v-else
                          >
                            Start typing to search for a company
                          </em>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col lg="4" md="5" sm="12" class="mr-4">
                    <b-form-group label="Asset Code">
                      <b-form-input
                        name="Asset Code"
                        type="search"
                        class="numFont"
                        v-model="filterBy.assetCode"
                        placeholder
                      />
                    </b-form-group>
                  </b-col>
                  <b-col lg="4" md="5" sm="12" class="mr-4">
                    <b-form-group
                      label="Disposal ID"
                      description="NOTE: Input the exact Disposal ID to search"
                    >
                      <b-form-input
                        name="Disposal ID"
                        type="search"
                        class="numFont"
                        v-model="filterBy.disposalRequestId"
                        placeholder="DRXXXXXXXXXXXXX"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row no-gutters>
                  <b-col sm="12">
                    <b-button class="mr-1" variant="success" @click="onFilterRequest">
                      Search
                    </b-button>
                    <b-button class="mr-1" variant="primary" @click="onClickReset">
                      Reset
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-collapse>
          </b-col>
        </b-row>

        <!-- Select Actions and Items Per Page Options -->
        <b-row>
          <b-col sm="6" md="3" class="mt-4 mb-2">
            <b-dropdown
              text=" Select Actions "
              v-if="!isAccounting"
              variant="dark"
              slot="append"
            >
              <b-dropdown-item v-b-modal.add-disposal v-show="!isViewer && !isApprover">
                Add Disposal Request
              </b-dropdown-item>
              <b-dropdown-item
                @click="approveSelectedDisposalRequests"
                v-show="isApprover"
              >
                Approve Disposal Request(s)
              </b-dropdown-item>
              <b-dropdown-item v-show="!isViewer">
                <json-excel
                  :data="exportData"
                  :fields="exportFields"
                  type="xls"
                  :name="fileName + '.xls'"
                >
                  Export Disposal Requests in Excel
                </json-excel>
              </b-dropdown-item>
              <b-dropdown-item v-show="!isViewer">
                <json-excel
                  :data="exportData"
                  :fields="exportFields"
                  type="csv"
                  :name="fileName + '.csv'"
                >
                  Export Disposal Requests to CSV
                </json-excel>
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
          <b-col sm="6" md="4" offset-md="5" class="mt-4 mb-2 text-md-right">
            <b-input-group prepend="Show" append="/ Page">
              <b-form-select :options="pageOptions" v-model="perPage" />
            </b-input-group>
          </b-col>
        </b-row>

        <b-table
          ref="disposalTable"
          show-empty
          striped
          hover
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :selectable="isApprover"
          :select-mode="isApprover ? 'multi' : 'single'"
          responsive
          @row-clicked="rowClicked"
          selected-variant="primary"
        >
          <template v-slot:cell(actions)="row">
            <DisposalRowActions
              :row="row"
              :isViewer="isViewer"
              :isAccounting="isAccounting"
              :isApprover="isApprover"
              :isManager="isManager"
              :isSuperVisor="isSuperVisor"
              :isSuperAdmin="isSuperAdmin"
            />
          </template>

          <template v-slot:cell(dateCreated)="row">
            {{ showFormattedDateTime(row.item.dateCreated) }}
          </template>

          <template v-slot:cell(status)="row">
            <DisposalRowStatus :row="row" />
          </template>

          <template slot="row-details" slot-scope="row">
            <DisposalDetailsView :row="row" />
          </template>
        </b-table>

        <b-row>
          <b-col sm="10" class="my-1">
            <b-button size="sm" @click="selectAllRows" v-show="isApprover">
              Select all </b-button
            >&nbsp;&nbsp;
            <b-button size="sm" @click="clearSelectedRows" v-show="isApprover">
              Clear selected
            </b-button>
            &nbsp;&nbsp;
            <span class="total-display">Total: {{ items.length }}</span>
          </b-col>
          <b-col sm="2">
            <b-pagination
              align="right"
              :total-rows="items.length"
              :per-page="perPage"
              v-model="currentPage"
              class="my-0"
            />
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- Modals here -->
    <AddDisposal
      :assetTypeOptions="filterByOptions.assetTypes"
      :isManager="isManager"
      :isMaintenance="isMaintenance"
      :isSuperVisor="isSuperVisor"
      :isSuperAdmin="isSuperAdmin"
      :loggedUser="loggedUser"
    />
    <EditDisposal
      :assetTypeOptions="filterByOptions.assetTypes"
      :isManager="isManager"
      :isMaintenance="isMaintenance"
      :isSuperVisor="isSuperVisor"
      :isSuperAdmin="isSuperAdmin"
      :loggedUser="loggedUser"
    />
    <CancelDisposal :loggedUser="loggedUser" />
    <SubmitDisposal :loggedUser="loggedUser" />
    <ConfirmNetBookDisposal :loggedUser="loggedUser" />
    <ForApprovalDisposal />
    <MultipleForApprovalDisposal />
    <MarkAsDoneDisposal />
  </div>
</template>

<script>
// Component
import AddDisposal from "@/views/maintenance/disposal/AddDisposal";
import EditDisposal from "@/views/maintenance/disposal/EditDisposal";
import CancelDisposal from "@/views/maintenance/disposal/CancelDisposal";
import SubmitDisposal from "@/views/maintenance/disposal/SubmitDisposal";
import ConfirmNetBookDisposal from "@/views/maintenance/disposal/ConfirmNetBookDisposal";
import ForApprovalDisposal from "@/views/maintenance/disposal/ForApprovalDisposal";
import MultipleForApprovalDisposal from "@/views/maintenance/disposal/MultipleForApprovalDisposal";
import MarkAsDoneDisposal from "@/views/maintenance/disposal/MarkAsDoneDisposal";
import DisposalRowActions from "@/views/maintenance/disposal/DisposalRowActions";
import DisposalRowStatus from "@/views/maintenance/disposal/DisposalRowStatus";
import DisposalDetailsView from "@/views/maintenance/disposal/DisposalDetailsView";

// Util
import { DateUtil } from "@/utils/dateutil";
import { ValidationUtil } from "@/utils/validationUtil";
import { DropDownItemsUtil } from "@/utils/dropDownItemsUtil";

// DAO & API
import companyDAO from "@/database/companies";
import disposalRequestDAO from "@/database/disposalRequest";
import disposalRequestApi from "@/api/disposalRequestApi";

// Others
import config from "@/config/env-constants";
import EventBus from "@/shared/event-bus";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import JsonExcel from "vue-json-excel";
import moment from "moment";
import _ from "lodash";

export default {
  name: "disposal",
  components: {
    Loading,
    JsonExcel,
    AddDisposal,
    EditDisposal,
    DisposalRowActions,
    DisposalRowStatus,
    DisposalDetailsView,
    CancelDisposal,
    SubmitDisposal,
    ConfirmNetBookDisposal,
    ForApprovalDisposal,
    MultipleForApprovalDisposal,
    MarkAsDoneDisposal,
  },
  data() {
    return {
      items: [],
      fields: [
        {
          key: "disposalRequestId",
          label: "Disposal ID",
          sortable: true,
        },
        {
          key: "dateCreated",
          label: "Date Created",
          sortable: true,
        },
        {
          key: "assetDetails.assetCode",
          label: "Asset Code",
          sortable: true,
        },
        {
          key: "assetDetails.assetType",
          label: "Asset Type",
          sortable: true,
        },
        {
          key: "maintenance",
          label: "Maintenance",
          sortable: true,
        },
        {
          key: "assetDetails.details.netBookValue",
          label: "Net Book Value",
          sortable: true,
          formatter: (value) => {
            return value
              ? value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " PHP"
              : "0.00 PHP";
          },
        },
        "status",
        {
          key: "actions",
          thClass: "text-center",
        },
      ],
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      pageOptions: [5, 10, 15, 25, 50, 100],
      sortBy: null,
      sortDesc: false,
      sortDirection: "asc",

      filterByOptions: {
        disposalStatusOptions: config.disposalStatusOptions,
        assetTypes: [],
      },

      defaultFilterBy: {
        dateFrom: moment().format("YYYY-MM-DD"),
        dateTo: moment().format("YYYY-MM-DD"),
        status: null,
        assetType: config.assetTypeDefaultValue,
        assetCode: "",
        disposalRequestId: "",
      },
      filterBy: {
        dateFrom: moment().format("YYYY-MM-DD"),
        dateTo: moment().format("YYYY-MM-DD"),
        status: null,
        assetType: "",
        assetCode: "",
        disposalRequestId: "",
      },
      prevFilter: {},
      allDisposalsObj: {},
      allAssetTypesObj: {},

      // array of selecteds from table
      selected: [],

      isSuperAdmin: this.$store.getters.isSuperAdmin,
      isViewer: this.$store.getters.isViewer,
      isAccounting: this.$store.getters.isAccounting,
      isApprover: this.$store.getters.isApprover,
      isSuperVisor: this.$store.getters.isSupervisor,
      isManager: this.$store.getters.isManager,
      isMaintenance: this.$store.getters.isMaintenance,
      loggedUser: this.$store.getters.loggedUser,
      loggedUserCompany: this.$store.getters.loggedUserCompany,

      // Check for loader
      isLoading: false,

      // Listener
      disposalListener: null,
    };
  },
  computed: {
    /**
     * Returns the set of data to be included in the export. For now this just
     * returns the data as is.
     *
     * @returns {Array} the set of data to be included in the export.
     */
    exportData() {
      return this.items;
    },

    /**
     * Derives the field information based from the data table configuration.
     *
     * @returns {object} the fields to be included in the export.
     */
    exportFields() {
      return {
        "Disposal ID": "disposalRequestId",
        "Date Created": "Date Created",
        "Asset Code": "assetDetails.details.assetCode",
        "Asset Type": "assetDetails.assetType",
        Maintenance: "createdBy",
        "Net Book Value": "assetDetails.details.netBookValue",
        Status: "status",
      };
    },

    fileName() {
      let currTimeStamp = DateUtil.getCurrentTimestamp();
      return "Disposal-" + DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp);
    },
    dateFrom() {
      const dateTo = moment();
      const dateFrom = dateTo.add(-30, "days");
      return dateFrom.format("YYYY-MM-DD");
    },
    dateTo() {
      return moment().format("YYYY-MM-DD");
    },
  },
  mounted() {
    setTimeout(async () => {
      try {
        // Filter Access
        if (this.$store.getters.isScanner) {
          this.$router.push("/dashboard");
          this.$toaster.warning("You are not allowed to access this page.");
        }

        if (this.$store.getters.isApprover) {
          this.filterByOptions = {
            ...this.filterByOptions,
            disposalStatusOptions: config.disposalStatusOptionsApprover,
          };
        }

        if (this.$store.getters.isAccounting) {
          this.filterByOptions = {
            ...this.filterByOptions,
            disposalStatusOptions: config.disposalStatusOptionsAccounting,
          };
        }

        const approverUsersObj = _.filter(
          {
            ...this.$store.getters.users,
          },
          (o) => {
            return o.type === config.approverRole;
          }
        );

        this.allAssetTypesObj = { ...this.$store.getters.assetTypes };
        this.filterByOptions.assetTypes = DropDownItemsUtil.retrieveAssetTypes(
          this.allAssetTypesObj,
          true
        );

        this.defaultFilterBy.dateFrom = this.dateFrom;
        this.defaultFilterBy.dateTo = this.dateTo;

        await this.resetFilters(true);
        await this.setupListener();
      } catch (_error) {
        this.$toaster.error("Error loading data. Please reload the page again.");
      } finally {
        // hide loading indicator
        this.isLoading = false;
      }
    }, config.timeout);

    // Event Listeners
    EventBus.$on("onCloseDisposalRequest", (disposalObj) => {
      this.updateTable(disposalObj);
    });
    EventBus.$on("onCloseMultipleDisposalRequest", (disposalObjs) => {
      _.forEach(disposalObjs, (disposalObj) => {
        this.updateTable(disposalObj);
      });
    });
  },
  created() {
    this.$watch(
      () => this.$route.params.id,
      (newId) => {
        // reset to default
        this.filterBy = {
          ...this.defaultFilterBy,
        };
        this.filterBy.disposalRequestId = newId;
        this.onFilterRequest();
      }
    );
  },
  methods: {
    listenerCallback(type, disposalRequest) {
      if (
        (type === "added" || type === "modified") &&
        disposalRequest.companyId === this.loggedUserCompany.id
      ) {
        this.allDisposalsObj[disposalRequest.id] = disposalRequest;
        this.filterDisposals(); // Apply any necessary filtering after the update
      }
    },
    updateTable(disposalObj) {
      if (_.isEmpty(disposalObj)) {
        return;
      }
      this.allDisposalsObj[disposalObj.id] = disposalObj;

      this.filterDisposals();
    },
    async onFilterRequest() {
      if (!this.validateFilter()) {
        return;
      }

      if (!_.isEqual(this.filterBy, this.prevFilter)) {
        await this.retrieveData();
        this.prevFilter = {
          ...this.filterBy,
        };
      }
    },
    validateFilter() {
      let isValid = true;

      if (_.isEmpty(this.filterBy.dateFrom) && _.isEmpty(this.filterBy.dateTo)) {
        this.$toaster.warning("Date From and Date To are required.");
        isValid = false;
      } else if (
        (_.isEmpty(this.filterBy.dateFrom) && !_.isEmpty(this.filterBy.dateTo)) ||
        (!_.isEmpty(this.filterBy.dateFrom) && _.isEmpty(this.filterBy.dateTo))
      ) {
        this.$toaster.warning(
          "Invalid Date Range. Date From and Date To must both have value."
        );
        isValid = false;
      } else if (this.filterBy.dateFrom > this.filterBy.dateTo) {
        this.$toaster.warning("Invalid Date Range. Date From must be less than Date To.");
        isValid = false;
      } else if (
        DateUtil.getNoOfDays(this.filterBy.dateFrom, this.filterBy.dateTo) > 90
      ) {
        this.$toaster.warning(
          "Invalid Date Range. Data range is allowed up to 90 days difference."
        );
        isValid = false;
      } else if (!this.isValidAssetCode(this.filterBy.assetCode)) {
        this.$toaster.warning(
          `Invalid Asset Code. "${this.filterBy.assetCode}" doesn't follow any of your asset tagging format.`
        );
        isValid = false;
      } else if (!ValidationUtil.isAlphaNumeric(this.filterBy.disposalRequestId)) {
        this.$toaster.warning("Invalid disposal ID. Please enter a valid disposal ID");
        isValid = false;
      }

      return isValid;
    },
    dateFromDisabled(_ymd, date) {
      return date > new Date();
    },
    showFormattedDateTime(date) {
      return DateUtil.getFormattedDateWithTime(date);
    },
    isValidAssetCode(assetCode) {
      return (
        _.isEmpty(assetCode) ||
        ValidationUtil.isValidAssetCode(this.allAssetTypesObj, assetCode)
      );
    },
    async resetFilters(fromMount) {
      if (!_.isEqual(this.filterBy, this.defaultFilterBy)) {
        // reset to default
        this.filterBy = {
          ...this.defaultFilterBy,
        };
        this.prevFilter = {
          ...this.filterBy,
        };

        if (fromMount && this.$route.params.id) {
          let newId = this.$route.params.id;
          this.filterBy.disposalRequestId = newId;
        }

        // reset validation
        this.$validator.reset();
        this.errors.clear();

        await this.retrieveData();
      }
    },
    async onClickReset() {
      if (this.$route.params.id) {
        let pathSegments = this.$route.path.split("/");
        pathSegments.pop(); // Remove the last segment (id)
        let newPath = pathSegments.join("/");

        this.$router.replace({ path: newPath });
      }

      // Reset filters then setup listener to listen to all changes
      await this.resetFilters(false);
      await this.setupListener();
    },
    async retrieveData() {
      try {
        // show loading indicator
        this.isLoading = true;

        let param = this.getParam();

        const { data } = await disposalRequestApi.getDisposalRequest(param);
        this.allDisposalsObj = data.disposalRequests;

        this.filterDisposals();
      } catch (_error) {
        this.$toaster.error("Error loading data. Please reload the page again.");
      } finally {
        // hide loading indicator
        this.isLoading = false;
      }
    },
    filterDisposals() {
      let filteredObjs = {
        ...this.allDisposalsObj,
      };

      let fromTimestamp = DateUtil.startDateTimeStamp(new Date(this.filterBy.dateFrom));
      let toTimestamp = DateUtil.endDateTimeStamp(new Date(this.filterBy.dateTo));

      _.forEach(filteredObjs, (disposal, id) => {
        delete filteredObjs[id]["_showDetails"];

        if (disposal.dateCreated < fromTimestamp && disposal.dateCreated > toTimestamp) {
          delete filteredObjs[id];
        }

        let status = this.filterBy.status;
        if (status && status.length > 0 && status !== disposal.status) {
          delete filteredObjs[id];
        }

        let assetCode = this.filterBy.assetCode;
        if (
          assetCode &&
          assetCode.length > 0 &&
          assetCode !== disposal.assetDetails.assetCode
        ) {
          delete filteredObjs[id];
        }

        let assetType = this.filterBy.assetType;
        if (
          assetType &&
          assetType.length > 0 &&
          assetType.name != disposal.assetDetails.name
        ) {
          delete filteredObjs[id];
        }

        let disposalId = this.filterBy.disposalRequestId;
        if (
          disposalId &&
          disposalId.length > 0 &&
          disposalId !== disposal.disposalRequestId
        ) {
          delete filteredObjs[id];
        }
      });

      this.processDisposals(filteredObjs);
    },
    processDisposals(disposals) {
      this.items = Object.values(disposals);
      this.items.forEach((item) => {
        item["Date Created"] = this.getFormattedDateWithTime(item.dateCreated);
      });
      this.items = _.sortBy(this.items, ["dateCreated"]);
      this.items.reverse();

      if (this.isAccounting) {
        this.items = _.filter(
          this.items,
          (o) => o.status === config.disposalStatus.NBV_REVIEW
        );
      }

      if (this.isApprover) {
        this.items = _.filter(
          this.items,
          (o) =>
            (o.status === config.disposalStatus.FOR_APPROVAL ||
              o.status === config.disposalStatus.APPROVED) &&
            _.some(o.approvers, (approver) => approver.id === this.loggedUser.id)
        );
      }

      this.$store.dispatch("setAllDisposals", disposals);

      // refresh table
      if (this.$refs.disposalTable) {
        this.$refs.disposalTable.refresh();
      }
    },
    getFormattedDateWithTime(date) {
      return DateUtil.getFormattedDateWithTime(date);
    },
    getParam() {
      let filter = {
        ...this.filterBy,
      };
      filter.fromTimestamp = DateUtil.startDateTimeStamp(new Date(filter.dateFrom));
      filter.toTimestamp = DateUtil.endDateTimeStamp(new Date(filter.dateTo));
      filter.company = { id: this.loggedUserCompany.id };

      return {
        filterBy: filter,
        currUserId: this.loggedUser.id,
        view: this.isSuperAdmin ? config.view.ADMIN : config.view.COMPANY,
      };
    },
    rowClicked(item) {
      if (_.findIndex(this.selected, { id: item.id }) < 0) {
        this.selected.push(item);
      } else {
        for (let i = 0; i < this.selected.length; i++) {
          if (this.selected[i].id === item.id) {
            this.selected.splice(i, 1);
            break;
          }
        }
      }
    },
    selectAllRows() {
      if (this.$refs.disposalTable) {
        this.$refs.disposalTable.selectAllRows();
      }

      this.items.forEach((item) => {
        if (_.findIndex(this.selected, { id: item.id }) < 0) {
          this.selected.push(item);
        }
      });
    },
    clearSelectedRows() {
      if (this.$refs.disposalTable) {
        this.$refs.disposalTable.clearSelected();
      }

      // clear selected rows
      this.selected = [];
    },
    approveSelectedDisposalRequests() {
      if (this.selected.length === 0) {
        this.$toaster.warning("Please select at least 1 disposal request.");
        return;
      } else if (
        _.findIndex(this.selected, {
          status: config.disposalStatus.FOR_APPROVAL,
        }) < 0
      ) {
        this.$toaster.warning(
          'Please select at least one "For Approval" disposal request.'
        );
        return;
      }

      this.$bvModal.show("multiple-for-approval-disposal");
      EventBus.$emit("onUpdateMultipleDisposal", this.selected);
    },
    async setupListener() {
      // Filter children companies if current company is a parent company
      let currentCompany = await companyDAO.getCompanyById(this.loggedUserCompany.id);
      let companies = [];

      if (currentCompany.hasParentCompany) {
        companies = [currentCompany];
      } else {
        var siblingCompanyObjs = await companyDAO.getSiblingCompanies(
          this.loggedUserCompany.id
        );
        var siblingCompanies = Object.values(siblingCompanyObjs);
        companies = [...siblingCompanies];
      }

      // Don't add filterBy companies if has ID params
      // Disable temporary
      // if (!this.$route.params.id) {
      //   this.filterBy.companyIds = companies.map((company) => company.id);
      // }

      // Update listener
      this.disposalListener = disposalRequestDAO.getDisposalRequestListener(
        { ...this.filterBy, isSuperAdmin: this.isSuperAdmin },
        this.listenerCallback
      );
    },
  },
  beforeUnmount() {
    if (this.disposalListener != null) {
      // Stop listening to changes
      this.disposalListener();
    }
  },
  beforeDestroy() {
    EventBus.$off("onCloseDisposalRequest");
  },
};
</script>
